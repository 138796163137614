import { computed } from '@vue/composition-api'
import { useUtils } from '@core/libs/i18n'

export default function useOptions() {
  const { t } = useUtils()

  const statusOptions = computed(() => [
    { text: t('status.active'), value: 'A' },
    { text: t('status.inactive'), value: 'I' },
  ])

  const projectTypeOptions = computed(() => [
    { text: t('projects.private'), value: 'A' },
    { text: t('projects.public'), value: 'I' },
  ])

  return {
    statusOptions,
    projectTypeOptions,
  }
}
